import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import SEO from '../components/SEO'
import Header from '../components/Header'

const PageTemplate = ({ data }) => {
  const { title, metaDescription, body, image } = data.contentfulPage
  return (
    <Layout>
      <SEO
        title={title}
        description={
          metaDescription
            ? metaDescription.internal.content
            : body.childMarkdownRemark.excerpt
        }
      />
      <Header
        hideSearch
        headerText={title}
        image={image?.gatsbyImageData}
        imageAltText={image?.description || image?.title}
        imageCred={image?.description}
        height={'30vh'}
      />
      <Container>
        <PageBody body={body} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      image {
        gatsbyImageData
        title
        description
      }
    }
  }
`

export default PageTemplate
